import { Children } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
  },
}));

export default function PageWithStaticHeader({ children }) {
  const classes = useStyles();
  let appBar;
  let content;

  Children.forEach(children, (child) => {
    const element = {
      [PageWithStaticHeader.AppBar.type]() {
        appBar = child;
      },
      [PageWithStaticHeader.Content.type]() {
        content = child;
      },
    }[child.type.type];

    if (!element) {
      throw new Error('Unknown child type for PageWithStaticHeader');
    }
    element();
  });

  return (
    <>
      <AppBar position="static">
        <Toolbar>{appBar}</Toolbar>
      </AppBar>
      <main className={classes.content}>{content}</main>
    </>
  );
}
