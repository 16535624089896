import { Route, Switch } from 'react-router-dom';
import * as form from './form';
import NotFound from '@super-template/core/src/NotFound';
import path from './_path';

function Forms() {
  return (
    <Switch>
      <Route {...form} />
      <Route component={NotFound} />
    </Switch>
  );
}

export { path, Forms as component };
