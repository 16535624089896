import { useEffect } from 'react';
import { useIntl } from 'react-intl';

export default function useSetDocumentTitle(pageTitle) {
  const intl = useIntl();

  useEffect(() => {
    const title = intl.formatMessage({ defaultMessage: 'Formula' });
    document.title = pageTitle ? `${pageTitle} – ${title}` : title;
  }, [intl, pageTitle]);
}
