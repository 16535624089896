import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import config from '@visma/public.config';
import useLocale from '@visma/react-app-locale-utils/lib/useLocale.js';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.type === 'dark' ? '#1FA5FF' : '',
  },
}));

export default function Footer() {
  const [locale] = useLocale();
  const lang = locale.split('-')[0];
  const classes = useStyles();
  const footerLogo = config.footer?.logo?.primary[lang];
  const footerLogoSecondary = config.footer?.logo?.secondary?.[lang];
  const privacyStatement = config.privacyStatement?.[lang];
  const accessibilityStatement = config.accessibilityStatement?.[lang];
  const technicalSupportStatement = config.technicalSupportStatement?.[lang];

  return (
    <Box component="div" width="95%" textAlign="right">
      {footerLogo && (
        <img style={{ maxHeight: '75px' }} src={footerLogo} alt="" />
      )}
      {footerLogoSecondary && (
        <img style={{ maxHeight: '75px' }} src={footerLogoSecondary} alt="" />
      )}
      {privacyStatement && (
        <Typography>
          <Link
            href={privacyStatement}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <FormattedMessage defaultMessage="Tietosuojaseloste" />
          </Link>
        </Typography>
      )}
      {accessibilityStatement && (
        <Typography>
          <Link
            href={accessibilityStatement}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <FormattedMessage defaultMessage="Saavutettavuusseloste" />
          </Link>
        </Typography>
      )}
      {technicalSupportStatement && (
        <Typography>
          <Link
            href={technicalSupportStatement}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <FormattedMessage defaultMessage="Tekninen tuki" />
          </Link>
        </Typography>
      )}
    </Box>
  );
}
