import Typography from '@material-ui/core/Typography';
import FormsList from 'components/FormsList';
import Page from 'components/layouts/Page';
import messages from 'messages';
import { IsAuthenticated } from '@visma/react-keycloak';
import { FormattedMessage, useIntl } from 'react-intl';

export const path = '/';

export const exact = true;

export const component = function Home() {
  const intl = useIntl();

  return (
    <Page title={intl.formatMessage(messages.home)}>
      <Typography component="h2" variant="h5" gutterBottom>
        <FormattedMessage defaultMessage="Lomakkeet" />
      </Typography>
      <IsAuthenticated
        fallback={<FormsList status="published" visibility="public" />}
      >
        <FormsList status="published" />
      </IsAuthenticated>
    </Page>
  );
};
