import { ListItemIcon } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import useDarkMode from 'use-dark-mode';
import Icon from './Icon';

// To make keyboard navigation work, <Menu> needs to render <MenuItem> directly.
// Instead of a component that renders MenuItem, we use custom hook to return props.
export default function useMenuItemProps({ onClick }) {
  const darkMode = useDarkMode();

  return {
    onClick: () => {
      onClick();
      darkMode.toggle();
    },
    children: (
      <>
        <ListItemIcon>
          <Icon fontSize="small" />
        </ListItemIcon>
        {darkMode.value ? (
          <FormattedMessage defaultMessage="Vaalea väriteema" />
        ) : (
          <FormattedMessage defaultMessage="Tumma väriteema" />
        )}
      </>
    ),
  };
}
