import { useEffect } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ExpandMore, Language } from '@material-ui/icons';
import displayNames from '@visma/react-app-locale-utils/lib/displayNames';
import useDisplayName from '@visma/react-app-locale-utils/lib/useDisplayName';
import useLocale from '@visma/react-app-locale-utils/lib/useLocale';
import { useIntl } from 'react-intl';
import useMenu from './hooks/useMenu';

const useStyles = makeStyles((theme) => ({
  text: {
    margin: theme.spacing(0, 0.5, 0, 1),
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));

export default function LocaleSwitcher() {
  const classes = useStyles();
  const intl = useIntl();
  const { anchorEl, isOpen, menuId, handleOpen, handleClose } =
    useMenu('locale-switcher');
  const [locale, setLocale] = useLocale();

  useEffect(() => {
    document.documentElement.lang = locale.split('-')[0];
  }, [locale]);

  return (
    <>
      <Button
        aria-label={intl.formatMessage({
          defaultMessage: 'kielivalikko',
        })}
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleOpen}
        color="inherit"
      >
        <Language />
        <span className={classes.text}>{useDisplayName()}</span>
        <ExpandMore fontSize="small" />
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={handleClose}
      >
        {displayNames.map((displayName) => (
          <MenuItem
            key={displayName.locale}
            selected={locale === displayName.locale}
            lang={displayName.locale.split('-')[0]}
            onClick={() => {
              setLocale(displayName.locale);
              handleClose();
            }}
          >
            {displayName.value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
