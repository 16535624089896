import { useState, useCallback } from 'react';

export default function useMenu(menuId) {
  const [anchorEl, setAnchorEl] = useState(null);
  return {
    anchorEl,
    isOpen: Boolean(anchorEl),
    menuId,
    handleOpen: useCallback(
      ({ currentTarget }) => setAnchorEl(currentTarget),
      []
    ),
    handleClose: useCallback(() => setAnchorEl(null), []),
  };
}
