import Highlighter from 'react-highlight-words';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  highlight: {
    backgroundColor: theme.palette.primary.highlight,
  },
}));

export default function ({ name, searchWords }) {
  const classes = useStyles();

  return (
    <Highlighter
      highlightClassName={classes.highlight}
      searchWords={searchWords}
      autoEscape
      textToHighlight={name}
    />
  );
}
