import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Info from '@material-ui/icons/Info';
import { FormattedMessage } from 'react-intl';
import AppInfoDialog from './AppInfoDialog';

export default function InfoMenuItem(props) {
  return (
    <AppInfoDialog>
      <MenuItem {...props}>
        <ListItemIcon>
          <Info fontSize="small" />
        </ListItemIcon>
        <FormattedMessage defaultMessage="Tietoja" />
      </MenuItem>
    </AppInfoDialog>
  );
}
