import { defineMessages } from 'react-intl';

export default defineMessages({
  back: { defaultMessage: 'Takaisin' },
  cancel: { defaultMessage: 'Peruuta' },
  close: { defaultMessage: 'Sulje' },
  delete: { defaultMessage: 'Poista' },
  edit: { defaultMessage: 'Muokkaa' },
  submit: { defaultMessage: 'Lähetä' },
});
