import { useParams } from 'react-router-dom';
import Page from 'components/layouts/Page';
import { Form as FormulaForm, useFormRev } from '@visma/formula';
import useQueryParamWithDefaultValue from '@postinumero/use-query-param-with-default-value';
import { StringParam } from 'use-query-params';

export const path = '/formdata/:formId/:formRev/:formDataId';

export const component = function FormData() {
  const { formId, formRev, formDataId } = useParams();
  const [credentials] = useQueryParamWithDefaultValue(
    undefined,
    'credentials',
    StringParam
  );
  const [formDataAction] = useQueryParamWithDefaultValue(
    undefined,
    'formDataAction',
    StringParam
  );

  const form = useFormRev(formId, formRev, formDataId);

  return (
    <Page title={form.title?.fi}>
      <FormulaForm
        id={formId}
        rev={formRev}
        //dataId={formDataId}
        credentials={credentials}
        formDataAction={formDataAction}
      />
    </Page>
  );
};
