import NotFound from '@super-template/core/src/NotFound';
import { generatePath, Redirect, Route, Switch } from 'react-router';
import * as admin from './admin';
import * as forms from './forms';
import * as form from './forms/form';
import * as home from './home';
import * as formData from './formData';

export default function Routes() {
  return (
    <Switch>
      {/* legacy path */}
      <Route
        path={['/forms/:id/fill/:dataId?', '/public/:id/fill/:dataId?']}
        render={({ match }) => (
          <Redirect
            replace
            to={generatePath(form.path, { id: match.params.id })}
          />
        )}
      />
      <Route {...admin} />
      <Route {...forms} />
      <Route {...home} />
      <Route {...formData} />
      {/* legacy path */}
      <Route
        exact
        path="/config/*"
        render={({ match }) => (
          <Redirect replace to={`/admin/${match.params[0]}`} />
        )}
      />
      {/* legacy path */}
      <Route exact path="/public" render={() => <Redirect replace to="/" />} />
      <Route component={NotFound} />
    </Switch>
  );
}
