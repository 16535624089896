import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import useMenu from '@super-template/core/src/hooks/useMenu';
import { useCurrentUser, useKeycloak } from '@visma/react-keycloak';
import { FormattedMessage, useIntl } from 'react-intl';

export default function UserMenu() {
  const intl = useIntl();
  const user = useCurrentUser();
  const { anchorEl, isOpen, menuId, handleOpen, handleClose } =
    useMenu('user-menu');
  const { keycloak } = useKeycloak();

  return (
    <>
      <IconButton
        aria-label={intl.formatMessage({ defaultMessage: 'käyttäjävalikko' })}
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleOpen}
        color="inherit"
      >
        <AccountCircleIcon />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={handleClose}
      >
        <MenuItem button={false}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <FormattedMessage
            defaultMessage="Kirjautunut: {name}"
            values={{ name: user.name }}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            keycloak.logout();
            handleClose();
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <FormattedMessage defaultMessage="Kirjaudu ulos" />
        </MenuItem>
      </Menu>
    </>
  );
}
