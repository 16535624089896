import {
  Chip,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Box,
  Tooltip,
} from '@material-ui/core';
import useQueryParamWithDefaultValue from '@postinumero/use-query-param-with-default-value';
import { useLocalizeConfig } from '@visma/formula';
import config from '@visma/public.config';
import { useHasRole } from '@visma/react-keycloak';
import Filter from 'components/Filter';
import { generatePath, Link } from 'react-router-dom';
import { StringParam } from 'use-query-params';
import FormListItemTitle from './FormListItemTitle';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(3),
    wordBreak: 'break-word',
  },
  listItem: {
    paddingRight: (props) => `${props.actionsCount * 48}px`,
  },
}));

function useFilter(name) {
  return useQueryParamWithDefaultValue('', name, StringParam);
}

export default function ItemsList({
  itemPath,
  items,
  showId,
  Actions,
  actionsCount = 1,
  filterParamName = 'filter',
  showUsagePrimary,
  showUsageSecondary,
}) {
  const isConfigurer = useHasRole({
    resource: { [config.keycloak.clientId]: 'configurer' },
  });
  const localizeConfig = useLocalizeConfig();
  const classes = useStyles({ actionsCount: actionsCount });
  const [filter, setFilter] = useFilter(filterParamName);

  let itemsFiltered = items;
  const filterLowerCase = filter?.toLowerCase();
  if (filter) {
    itemsFiltered = itemsFiltered.filter(
      showId
        ? (item) =>
            `${item._id} ${localizeConfig(item).title.toLowerCase()}`.includes(
              filterLowerCase
            )
        : (item) =>
            localizeConfig(item).title.toLowerCase().includes(filterLowerCase)
    );
  }

  return (
    <>
      <Filter
        filter={filter}
        setFilter={setFilter}
        filterName={filterParamName}
      />
      <Paper className={classes.paper}>
        <List>
          {itemsFiltered.map((item) => (
            <ListItem
              className={classes.listItem}
              key={item._id}
              button
              component={Link}
              to={generatePath(itemPath, { id: item._id })}
            >
              <ListItemText
                primary={
                  <FormListItemTitle
                    name={
                      showId
                        ? `${localizeConfig(item)._id} ${
                            localizeConfig(item).title
                          }`
                        : localizeConfig(item).title
                    }
                    searchWords={[filter]}
                  />
                }
                secondary={isConfigurer && item.notes}
              />
              {(showUsageSecondary || showUsagePrimary) && (
                <Box component="span" sx={{ marginRight: 10 }}>
                  {showUsageSecondary && (
                    <Tooltip title={'Käytössä kysymysryhmissä'}>
                      <Chip
                        label={item.usageSecondary ? item.usageSecondary : '0'}
                        color="success"
                        size="small"
                        variant="outlined"
                        style={{ margin: 5 }}
                      />
                    </Tooltip>
                  )}
                  {showUsagePrimary && (
                    <Tooltip title={'Käytössä lomakkeilla'}>
                      <Chip
                        label={item.usagePrimary ? item.usagePrimary : '0'}
                        color="primary"
                        size="small"
                        variant="outlined"
                        style={{ margin: 5 }}
                      />
                    </Tooltip>
                  )}
                </Box>
              )}
              {Actions && (
                <ListItemSecondaryAction>
                  <Actions {...item} />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  );
}
