import { FormulaProvider } from '@visma/formula';
import useDateFnsLocale from '@visma/react-app-locale-utils/lib/useDateFnsLocale.js';

export default function FormulaConfig({ children }) {
  return (
    <FormulaProvider dateFnsLocale={useDateFnsLocale()}>
      {children}
    </FormulaProvider>
  );
}
