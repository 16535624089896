import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
} from '@material-ui/core';
import { cloneElement, forwardRef, useImperativeHandle, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Extendable, { Optional } from '../Extendable';

export default forwardRef(function Alert(
  { overrides, children, ...otherProps },
  ref
) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (event) => {
    children?.props.onClick?.();
    setOpen(true);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
    },
    close() {
      setOpen(false);
    },
  }));

  return (
    <>
      <Extendable overrides={overrides}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          {...otherProps}
        >
          <Optional>
            <DialogTitle id="dialog-title" />
          </Optional>
          <Optional>
            <DialogContent>
              <DialogContentText id="dialog-description" />
            </DialogContent>
          </Optional>
          <Optional>
            <List />
          </Optional>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
              variant="contained"
              color="primary"
              autoFocus
            >
              <FormattedMessage defaultMessage="OK" />
            </Button>
          </DialogActions>
        </Dialog>
      </Extendable>
      {children && cloneElement(children, { onClick: handleClickOpen })}
    </>
  );
});
