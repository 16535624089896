import { Alert, AlertTitle } from '@material-ui/lab';
import { FormattedMessage, useIntl } from 'react-intl';
import Page from '../layouts/Page';
import messages from '../../messages';
import { Button } from '@material-ui/core';

export function ErrorFallback({ error }) {
  const intl = useIntl();

  // eslint-disable-next-line
  console.error(error);

  const forcePageRefresh = () => {
    window.location.reload();
  };

  return (
    <Page title={intl.formatMessage(messages.error)}>
      <Alert severity="warning">
        <AlertTitle>
          <FormattedMessage defaultMessage="Tapahtui virhe!" />
        </AlertTitle>
        <FormattedMessage defaultMessage="Tapahtui odottamaton virhe. Lataa sivu uudelleen alla olevasta napista." />
      </Alert>
      <Button variant="contained" color="primary" onClick={forcePageRefresh}>
        <FormattedMessage {...messages.reloadPage} />
      </Button>
    </Page>
  );
}
