import { DialogTitle, List, ListItem, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import messages from '@super-template/messages';
import useDateFnsLocale from '@visma/react-app-locale-utils/lib/useDateFnsLocale';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import Alert from '../dialog/Alert';

export default function InfoMenuItem({ onClick, ...otherProps }) {
  const locale = useDateFnsLocale();

  return (
    <Alert
      fullWidth
      maxWidth="xs"
      overrides={
        <>
          <DialogTitle>
            <FormattedMessage defaultMessage="Tietoja" />
          </DialogTitle>
          <List dense>
            <ListItem>
              <ListItemText
                primary={<FormattedMessage defaultMessage="Versio" />}
                secondary={process.env.GIT_VERSION}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <FormattedMessage defaultMessage="Päivitetty viimeksi" />
                }
                secondary={format(new Date(process.env.GIT_AUTHOR_DATE), 'Pp', {
                  locale,
                })}
              />
            </ListItem>
          </List>
          <Button>
            <FormattedMessage {...messages.close} />
          </Button>
        </>
      }
      {...otherProps}
    />
  );
}
