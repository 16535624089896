import { IconButton, Menu, MenuItem } from '@material-ui/core';
import Settings from '@material-ui/icons/Settings';
import useMenu from '@super-template/core/src/hooks/useMenu';
import { useIntl } from 'react-intl';
import useDarkModeMenuItemProps from '../darkMode/useMenuItemProps';
import InfoMenuItem from './InfoMenuItem';

export default function SettingsMenu() {
  const intl = useIntl();
  const { anchorEl, isOpen, menuId, handleOpen, handleClose } =
    useMenu('settings-menu');

  return (
    <>
      <IconButton
        aria-label={intl.formatMessage({ defaultMessage: 'Asetusvalikko' })}
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleOpen}
        color="inherit"
      >
        <Settings />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={handleClose}
      >
        <MenuItem {...useDarkModeMenuItemProps({ onClick: handleClose })} />
        <InfoMenuItem onClick={handleClose} />
      </Menu>
    </>
  );
}
