import { original, produce } from 'immer';
import { Children, cloneElement, Fragment } from 'react';

const toArray = (element) => {
  return element
    ? Array.isArray(element)
      ? element
      : element.type === Fragment
      ? toArray(element.props.children)
      : [element]
    : [];
};

export const Optional = ({ children }) => children;

export function extend(baseElements, overrides) {
  return toArray(baseElements).map((baseElement) => {
    overrides = toArray(overrides);
    const isOptional = baseElement?.type === Optional;
    if (isOptional) {
      baseElement = Children.only(baseElement.props.children);
    }
    const override = overrides.find(
      (override) =>
        override.type === baseElement?.type && override.key === baseElement.key
    );
    const val = override
      ? cloneElement(baseElement, override.props)
      : isOptional
      ? null
      : baseElement &&
        produce(baseElement, (draftBaseElement) => {
          const baseElement = original(draftBaseElement);
          const children = baseElement?.props?.children;
          if (children) {
            draftBaseElement.props.children = extend(children, overrides);
          }
        });
    return val;
  });
}

export default function Extendable({ children: baseElements, overrides }) {
  return extend(baseElements, overrides);
}
