import { Suspense, useState } from 'react';
import PageWithStaticHeader from '@super-template/lab/src/PageWithStaticHeader';
import { CircularProgress } from '@material-ui/core';
import AppBar from './AppBar';
import useSetDocumentTitle from './useSetDocumentTitle';
import Footer from './Footer';
import Banner from './Banner';
import { useConfig } from '@visma/formula';

export default function Page({ title, children }) {
  useSetDocumentTitle(title);

  const [bannerConfig] = useState(useConfig()?.bannerConfig);

  return (
    <>
      {bannerConfig?.enabled && <Banner config={bannerConfig} />}
      <PageWithStaticHeader>
        <PageWithStaticHeader.AppBar>
          <AppBar title={title} />
        </PageWithStaticHeader.AppBar>
        <PageWithStaticHeader.Content>
          <Suspense fallback={<CircularProgress />}>{children}</Suspense>
          <Footer />
        </PageWithStaticHeader.Content>
      </PageWithStaticHeader>
    </>
  );
}
