import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import messages from 'messages';
import { useIntl } from 'react-intl';

export default function Filter({ filter, setFilter, filterName }) {
  const intl = useIntl();

  const handleChange = (event) => {
    setFilter(event.target.value, 'replaceIn');
  };

  const handleClick = () => {
    setFilter('', 'replaceIn');
  };

  return (
    <Box pb={1}>
      <FormControl fullWidth variant="outlined">
        <TextField
          id={`search-${filterName}`}
          aria-label={intl.formatMessage(messages.search)}
          label={intl.formatMessage(messages.search)}
          onChange={handleChange}
          value={filter}
          variant="outlined"
          size="small"
          InputLabelProps={{
            htmlFor: `search-${filterName}`,
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                size="small"
                onClick={handleClick}
                disabled={filter === ''}
                aria-label={intl.formatMessage(messages.empty)}
              >
                <ClearIcon fontSize="medium" />
              </IconButton>
            ),
          }}
        />
      </FormControl>
    </Box>
  );
}
