import { FormattedMessage } from 'react-intl';
import { Alert } from '@material-ui/lab';

const severities = {
  DRAFT: 'info',
  PUBLISHED: 'warning',
};

export default ({ status }) => (
  <Alert severity={severities[status] ?? 'error'}>
    <FormattedMessage
      defaultMessage={`Kyselyn tila: {status, select,
DRAFT {luonnos}
PUBLISHED {julkaistu}
other {tuntematon}}`}
      values={{
        status: status,
        strong: (chunks) => <strong>{chunks}</strong>,
      }}
    />
  </Alert>
);
